.__json-pretty__ {
  line-height: 1.3;
  color: #000;
  background: #fff;
  overflow: auto;
}

.__json-pretty__ .__json-key__ {
  color: #000
}

.__json-pretty__ .__json-value__ {
  color: #6b21a8
}

.__json-pretty__ .__json-string__ {
  color: #6b21a8
}

.__json-pretty__ .__json-boolean__ {
  color: #6b21a8
}

.__json-pretty-error__ {
  line-height: 1.3;
  color: #000;
  background: #fff;
  overflow: auto
}