/// Misc
//////////////////////////

.clipboard-success-message{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $success;
    background: rgba($white,.9);
    border-radius: $border-radius;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
    .clipboard-success &{
        opacity: 1;
    }
}

// Action
.nk-wg-action{
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
        padding: 0.5em 0;
        color: #670199;
    }

    &-content{
        position: relative;
        @if($rtl==false){
            padding-left: 2rem;
            padding-right: .5rem;
        }
        @if($rtl==true){
            padding-right: 2rem;
            padding-left: .5rem;
        }
        .icon{
            font-size: 1.5rem;
            @if($rtl==false){
                left:0;
            }
            @if($rtl==true){
                right:0;
            }
            position: absolute;
            color: $base-light;
        }
        .title{
            font-size: $fx-sz-14;
            font-weight: $fw-medium;
            padding-bottom: 0.25rem;
        }
        p{
            color: $base-light;
            font-size: $fx-sz-13;
            line-height: 1.25rem;
            strong{
                color: $accent-color;
            }
        }
    }
}
